.paginator{
  margin: 0;
  padding: 0;

  li{
    list-style: none;
    display: inline-block;
    margin: 0 1px;

    &.active,&:hover{
      a{
        background: $main-color;
        color: #ffffff;
      }
    }

    a{
      text-decoration: none;
      padding: 5px 10px;
      font-size: 14px;
    }
  }
}