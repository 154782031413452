.footer-site {
    clear: both;
    padding: 10px 0;
    background: $footer-bg;
    *{
        color: $footer-color;
    }
    
    .group-info{
        h3{
            margin-top: 0;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .list-unstyled{
        margin: 0;
        li{
            margin-bottom: 5px;
            .fa{
                font-size: 16px;
                margin-right: 10px;
            }
        }
    }

    .footer-menus{
        list-style: none;
        text-align: right;
        margin-bottom: 5px;
        margin-top: 30px;
    }

    p { margin: 0; }

    p, a, a:hover{
        color: $footer-hover-color;
    }
    
    .footer-socials {
        margin: 0px;
        text-align: right;
    }
}