.promotions{
    .gift-icons{
        background-image: url("../img/promote-icon.png");
        background-position: left;
        background-repeat: no-repeat;
        display: block;
        width: 50px;
        height: 50px;
        background-size: cover;
        position: absolute;
        left: 15px;
        top: 0;
    }
}

.widget-shopping{
    clear: both;
    .shopping-list{
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        li{
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid #eeeeee;
            .product-thumb{
                float: left;
                max-width: 30%;
                margin-right: 10px;
            }
    
            .overflow-h{
                h5{
                    margin: 0 0 5px 0;
                    font-weight: bold;
                    font-size: 12px;
                    a{
                        font-weight: bold;
                        font-size: 12px;
                    }
                }
                .item-extra{
                    .item-extra-price{
                        font-weight: bold;
                    }
                    .item-extra-del{
                        width: 30px;
                        float: right;
                        font-size: 15px;
                        text-align: center;
                    }
                }
            }
        }        
    }
    ul{
        padding: 0;
        margin: 0;
        li{
            list-style: none;
        }
    }

    .shopping-info {
        margin-bottom: 10px;
        .total-item{

        }
        .total-item-2{
            float: right;
        }
    }

    form{
        .form-group{
            margin-bottom: 5px;
        }
    }

    .summary-contact-form{
        margin-bottom: 15px;
        position: relative;
    }
}

.chanel-contact-info {
    margin-top: 20px;
    ul{
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            display: inline;
            margin: 0 5px;
            a{
                text-decoration: none;
                img{
                    max-height: 30px;
                    width: auto;
                }
            }
        }
    }
}

.product-booking-form{
    .group-actions{
        clear: both;
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        .input{
            &.number{
                float: left;
                label{
                    margin-right: 10px;
                }
                input{
                    max-width: 50px;
                    padding: 0 0 0 5px;
                }
            }
        }
        button,.fn-help{
            float: right;
            .fa{
                margin-right: 5px;
            }
        }
    }
}
.shop-header{
    display: inline-block;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 10px;
    line-height: 32px;
    label{
        margin: 0;
    }
}

.group-price {
    label{
        margin: 0;
        color: #ffffff;
        float: left;
        padding: 6px 10px;
    }

    .old-price{
        text-decoration: line-through;
        background: $blue-color;
    }

    .new-price{
        background: $gold-color2;
        a{
            color: #ffffff;
        }
    }
}

.form-overlay-active{
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: 9;
}

.form-overlay-loader{
    z-index: 10;
    .fa-spinner{
        position: absolute;
        top: 40%;
        left: 45%;
        width: 40px;
        height: 40px;
        font-size: 36px;
        z-index: 10;
    }
}