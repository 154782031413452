.gallery-list{
  .gallery-item{
    margin-bottom: 20px;

    .gallery-thumb{
      border: 1px solid transparent;
      &:hover{
        border-color: $gold-color2;
      }
    }

    .gallery-des{
      h2,h3,h4{
        font-size: 16px;
        text-transform: none;
        font-weight: 700;
      }
    }
  }
}