.sidebar{
  .list-unstyled {
    li {
      margin-bottom: 5px;
      &.fix-phone{
        font-size: 15px;
        font-weight: bold;
        color: #ff0000;
        i.fix-space-phone{
          margin-left: 20px;
        }
      }
      .fa{
        font-size: 15px;
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
}

.sidebar-category-list{
  border-radius: 0;
  a {
    i{
      margin-right: 5px;
    }
  }
}





.sidebar-category-list{
  border-radius: 0;
  .navbar{
    margin: 0;
  }
  .navbar-collapse{
    margin: 0;
    padding: 0;
    ul{    
      li{
        display: block;
        width: 100%;
        padding: 0;			
        margin: 0;
        position: relative;
  
        .dropdown-menu{
          left: 100%;
          top: 0;
          margin: 0;
          padding: 0;
          border: 1px solid #f5f5f5;
  
          a{
            padding: 0;
            margin: 0;
            padding: 5px 0 5px 10px;
            display: block;
          }
        }
        &:hover{
          .dropdown-menu{
            display: block;
          }
          > a{
            background: $blue-color;
            color: #ffffff;
            i, span{
              color: #ffffff;
            }
          }
        }
        a{
          padding: 5px 0 5px 10px;
          display: block;
          font-size: 13px;
          text-transform: none;
          line-height: 18px;
        }			
      }		
    } 
  }
}

@media (max-width: 991px) {
  .sidebar{
    .navbar-toggle{
      margin-bottom: 3px;
      margin-top: 3px;
      .icon-bar{
        background: #ffffff;
      }
    }
    .panel-group .panel{
      border-radius: 0;
    }

    ul.navbar-nav{
      margin: 0;
    }

    ul li .dropdown-menu{
      position: static;
      margin-left: 20px;
    }

    .sidebar-category-list .navbar-collapse ul li .dropdown-menu{
      float: none;
    }

    .sidebar-category-list .navbar-collapse ul li .dropdown-menu a{
      padding-left: 25px;
    }
  }
}
