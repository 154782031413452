.header-site {
    .topbar-site {
        z-index: 99;
        padding: 10px 0;
        position: relative;
        background: $header-top-bg;
        *{
            color: $header-top-color;
        }

        ul{
            margin: 0;
            padding: 0;
            li{
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        ul.left-topbar {
            padding-left: 0;
            margin-bottom: 1px;
        }
        
        ul.left-topbar > li {
            display: inline;
            list-style: none;
            position: relative;
            margin-right: 15px;
            padding-bottom: 15px;
        }
        
        ul.left-topbar > li > a {
            font-size: 12px;
            text-transform: uppercase;
        }
        
        ul.left-topbar > li > a:hover {
            cursor: pointer;
        }
    }

    .navbar {
        margin-bottom: 0;
        padding-bottom: 5px;
        
        &.navbar-default {
            border: none;
            background: none;
        }
        >.container {
            .navbar-brand {
                margin: 0;
                padding: 5px 0 0 0;
                img#logo-header {
                    max-height: 50px;
                    width: auto;
                }
            }
        }
        
        .navbar-toggle .icon-bar{
            background: #000000;
        }
    }
}