.feature-heading{
    clear: both;
    margin: 0 0 10px 0;
    text-align: center;

    h2{
        color: #1d8dd9;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0 12px;
        position: relative;
        display: inline-block;
        line-height: 34px!important;
        margin: 0;

        &:after, &:before {
            content: " ";
            width: 70%;
            position: absolute;
            border-width: 1px;
            border-color: #1d8dd9;
            top: 15px;
            height: 6px;
            border-top-style: solid;
            border-bottom-style: solid;
        }
    }

    &.heading h2:before {
        right: 100%;
    }

    &.heading h2:after {
        left: 100%;
    }
}