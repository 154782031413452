@media (min-width: 992px) {
    .navbar-nav {
        float: right;
    }

    .navbar-nav li{
        position: relative;
    }

    .navbar-nav>li>a {
        border-top: 2px solid transparent;
        margin-top: -1px;
        text-transform: uppercase;
    }
    .navbar-nav>li>a:hover {
        border-top: 2px solid $main-color;
        background: transparent;
    }

    .navbar-nav>li:hover {
        >.dropdown-menu{
            display: block;
            >li:hover{
                >.dropdown-menu{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    margin: -2px 0px 0px -1px
                }
            }
        }
    }

    .dropdown-menu>li>a{
        &:hover{
            color: #ffffff;
            background: $main-color;
            span{
                color: #ffffff;
            }
        }
    }

    .dropdown-menu{
        border-top: 2px solid $main-color;
        border-radius: 0;
        padding: 0;

        li{
            a {
                padding: 5px 15px;
                &:hover{
                    color: #ffffff;
                    background: $main-color;
                }
            }
        }
    }
}