* {
    font-size: $main-font-size;
    font-family: $main-font-family;
    color: $text-color;
}

a {
    color : $a-color;
    text-decoration: none;
    &:hover {
        color : $ahover-color;
        text-decoration: underline;        
    }
}

img { max-width: 100%; height: auto; }

.clear { clear  : both; height : 1px; }
.clear-15 { clear  : both; height : 15px; }
.clear-20 { clear  : both; height : 20px; }
.clear-30 { clear  : both; height : 30px; }
.clear-40 { clear  : both; height : 40px; }
.clear-50 { clear  : both; height : 50px; }
.clear-60 { clear  : both; height : 60px; }

.section{
    clear: both;
    margin: 20px 0;
    padding: 0;
}

.btn {
	box-shadow: none;
	border-radius: 0;
}
.btn-default {
	border:  1px solid $btn-border-color;
}

.header-lable,
.btn-u {
	border: 0;
	color: $btn-color;
	font-size: 14px;
	cursor: pointer;
	font-weight: 400;
	padding: 6px 13px;
	position: relative;
	background: $btn-color-bg;
	white-space: nowrap;
	display: inline-block;
    text-decoration: none;
    .fa{
        color: $btn-color;
        margin-right: 5px;
    }
}
.btn-u:hover,
.btn-u:focus,
.btn-u:active,
.btn-u.active,
.open .dropdown-toggle.btn-u {
    background: $btn-color-bg-hover;
    color: $btn-color-hover;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

.no-margin-padding {
    margin  : 0;
    padding : 0;
    li {
        list-style : none;
        display    : inline-block;
    }
}

// .single-template
.single-template {
    margin-top: 20px;
}

.social-icons-list {
    i {
        font-size : 20px;
    }
}

.page-title {
    text-transform : uppercase;
}

.cell-table { display : table; padding : 0px; width: 100%; }
.cell-table-tr { display : table-row; padding : 0px; }
.cell-table-td {
    display       : table-cell;
    padding       : 0px;
    width         : 25%;
    border        : #ffffff solid 15px;
    border-top    : 0;
    border-bottom : 0;
}

.post-list-container,
.post-list-news{
    h4{
        line-height: 17px;
        a{
            font-weight: bold;
            font-size: 15px;
        }
    }
}


.product-list {
    h1.page-title {
        font-weight : 700;
        font-size   : 22px;
        color       : $second-color;
    }

    .product-list-3 {
        .cell-table-td {
            width         : 33.333333%;
            margin-bottom : 15px;
        }
    }
}

em.invalid {
    color     : $gold-color;
    font-size : 11px;
}

.datepicker {
    position : relative;
    .span2 {
        width         : 100px;
        padding-right : 40px;
    }

    span.add-on {
        position : absolute;
        right    : 15px;
        top      : 10px;
    }
}

.success-overlay{    
    .site-overlay-success{
        position: fixed;
        left: 1px;
        background: #b58f47;
        bottom: 20px;
        border-left: 5px solid #5fb611;
        color: #ffffff;
        z-index: 1000;
        p{
            margin: 0;
            padding: 10px;
        }
    }
}

.site-overlay-active {
    background : #000000;
    opacity    : 0.5;
    position   : fixed;
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0;
    z-index    : 9999;
    width      : 100%;
    height     : 100%;
}

.site-overlay-loader {
    background-image : url("../img/ajax-loader.gif");
    display          : block;
    width            : 48px;
    height           : 48px;
    position         : fixed;
    z-index          : 10000;
    top              : 49%;
    left             : 49%;
}

.sidebar {
    .group-info {
        h3 {
            text-transform : uppercase;
            font-weight    : bold;
            font-size      : 15px;
            margin-bottom  : 10px;
            margin-top: 0;
        }
    }
}