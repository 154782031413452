.main-layout{
    .col2-left-sidebar{
        .main-content{
            float: right;
        }

        .main-sidebar{
            float: left;
        }
    }

    .col2-right-sidebar{}
}



.fix-lr-mg-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.fix-lr-boder-10 {
    border-left-width: 10px !important;
    border-right-width: 10px !important;
}

.cell-table {
    display : table;
    padding : 0px;
    width: 100%;
}

.cell-table-tr {
    display : table-row;
    padding : 0px;

    .cell-table-td-first{
    }

    .cell-table-td-end{
    }

    .cell-table-td-item{
        padding-left: 7px;
        padding-right: 5px;
        margin-bottom: 15px;
    }
}

.cell-table-td {
    display       : table-cell;
    padding       : 0px;
    width         : 25%;
    border        : #ffffff solid 15px;
    border-top    : 0;
    border-bottom : 0;
    margin-bottom : 15px;
    .item-content{
        margin-top: 10px;
        .cell-item-des{}
    }
    
    h2, h3, h4, h5, h6 {
    }
}

.grid-list-3 {
    .cell-table-td {
        width : 33.333333%;
    }
}

.grid-list-4 {
    .cell-table-td {
        width : 25%;
    }
}

.item-thumb{
    a{
        display: block;
        img{}
    }
}
.item-content{
    h2, h4, h4, h5, h6{
        clear: both;
        margin-top: 0;
        font-size: $list-items-title-size;
        a{
            font-weight: bold;
            font-size: $list-items-title-size;
        }
    }
    p{}

    .item-readmore{
        font-weight: bold;
        a{}
    }
}
// List
.list-layout{
    .list-items{
        margin-bottom: $list-items-mg-bt;
    }
    .list-layout-item{
        .item-thumb{
            float: left;
            margin-right: $item-thumb-mg-right;
            max-width: $item-thumb-max-with;
            a{
                display: block;
                img{}
            }
        }
        .item-content{
            float: left;
            max-width: $item-content-max-with;
            h2, h4, h4, h5, h6{
                clear: both;
                margin-top: 0;
                font-size: $list-items-title-size;
                a{
                    font-weight: bold;
                    font-size: $list-items-title-size;
                }
            }
            p{}

            .item-readmore{
                font-weight: bold;
                a{}
            }
        }
    }

}

@media (max-width: 575px) {
    .col-xxs{
        width: 100%;
    }
}