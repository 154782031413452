$main-font-family: "Roboto", sans-serif;
$main-font-size: 13px;

$blue-color: #1d8dd9;
$gold-color: #b58f47;
$gold-color2: #f0ad4e;
$red-color:     #e74c3c;
$white-color: #ffffff;
$black-color-555: #555;

$main-color: #1d8dd9;
$second-color:#6b563f;

// Text and a tag
$text-color: #222222;
$a-color: $text-color;
$ahover-color: $blue-color;

// btn configs
$btn-color: #ffffff;
$btn-color-hover: #ffffff;
$btn-color-bg: $blue-color;
$btn-color-bg-hover: #e74c3c;
$btn-border-color: #555;
$btn-hover-border-color: #555;

$border-color: #dddddd;
$border-hover-color: #dddddd;

$header-top-bg: $blue-color;
$header-top-color: $white-color;
$header-hover-top-color: $white-color;

$footer-bg: $blue-color;
$footer-color: $white-color;
$footer-hover-color: $white-color;

$price-color: $red-color;

// Post list heading line-height
// h2, h3, h4, h5, h6
$heading-line-height: 20px;
$heading-font-size: 16px;
$heading-margin-top: 10px;
$heading-margin-bottom: 5px;
$heading-margin-right: 10px;
$heading-margin-left: 10px;


// list-layout
$list-items-title-size: $main-font-size + 1;
$list-items-mg-bt:20px;
$item-thumb-mg-right: 3%;
$item-thumb-max-with: 35%;
$item-content-max-with: 62%;
