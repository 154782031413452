.contact-us{
    iframe{
        max-width: 100%;
        min-height: 300px;
        height: auto;
    }
}

.contact-section-content{
    form{
        .form-group{
            span,
            textarea,
            input{
                background: transparent;
                color: #eeeeee;
            }

            textarea{
                width: 100%;
                color: #eeeeee;
            }
        }
    }
}

.border-red{
    border-color: #ff0000;
}

.show-error-message{
    .error,
    .wanning,
    .success{
        padding: 0 10px;
        color: #ff0000;
    }

    .error{
        
    }

    .wanning{
        
    }

    .success{
        
    }
}