.search {
    cursor: pointer;
    display: inline-block;
}

.search:hover {
    color: #bbb;
}

.search-open {
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    background: #333;

    .container {
        position: relative;
    }

    .search-close {
        top: 10px;
        right: 20px;
        color: #fff;
        cursor: pointer;
        position: absolute;
    }

    .form-control {
        padding: 0;
        color: #eee;
        border: none;
        min-height: 35px;
        font-weight: 200;
        background: #333;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .form-control::-moz-placeholder {
        color: #eee;
        font-weight: 200;
    }

    .form-control:-ms-input-placeholder {
        color: #eee;
        font-weight: 200;
    }

    .form-control::-webkit-input-placeholder {
        color: #eee;
        font-weight: 200;
    }
}