iframe{
    max-width: 100%;
}

.single-details {
    img {
        max-width: 100%!important;
        height: auto!important;
    }

    table{
        border: 1px solid $border-color;
        tr{
            th, td{
                padding: 5px;
                border-color: $border-color;
            }
        }
    }
}

.single-template {   
    clear: both;
    padding-bottom: 20px;

    h1{
        font-size: 20px;
        line-height: normal;
        padding-bottom: 3px;
        font-weight: bold;
        border-bottom: 1px solid $border-color;
        margin-top: 0;
    }
}

.page-header-fix{
    font-size: 20px;
    line-height: normal;
    padding-bottom: 3px;
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 0;
    
}

.single-albums{
    ul.info-quote-time{
        li{
            width: 50%;
            margin: 0 0 5px 0;
            padding: 0;            
            float: left;
            &.st-2 {
                text-align: right;
            }
        }
    }
}
.ajax-view-detail {
    ul{
        margin-bottom: 5px;
    }
}