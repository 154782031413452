.post-list-related {
    clear: both;
    margin: 20px 0;
  
    h4.related-title{
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 5px;
      font-weight: bold;
      font-size: 15px;
    }
  }