.download-file-post {
    display: block;
    margin: 10px 0;
    a {
        font-weight: bold;
        color: $gold-color;
        &.download-link {
            
        }
        &.view-link {
        }
    }
}