.btn-readmore{
    clear: both;
    margin-top: 20px;

    .btn-readmore-a{
        padding: 0 0 0 10px;
        background-color: #dd994c;
        transition: 0.2s ease-out;
        font-size: 12px;
        line-height: 1.42857;
        color: #fff;
        padding: 7px 0 6px 15px;

        &::after {
            content: "\f18e";
            font-family: 'FontAwesome';
            padding-left: 8px;
            padding-right: 8px;
            margin-left: 10px;
            padding-bottom: 3px;
            color: white;
            font-size: 17px;
            background-color: black;
            display: inline-block;
        }

        &:hover{
            border: none;
            background-color: #BA2A2A;
        }
    }
}

.section-header{
    clear: both;
    text-align: center;
    h2{
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;

        &:before, &:after{
            display: inline-block;
            content: "";
            border: 1px solid #dd994c;
            padding: 2px;
            margin-top: 5px;
            width: 120px;
            border-left: none;
            border-right: none;
            margin: 2px 10px;
        }

        &:after{
        }
    }
}



.about-section-1 {
    clear: both;
    display: inline-block;
    width: 100%;
    padding: 10px 0 10px 0;

    &.about-section-content{
        h2, h3{
            margin: 0 0 25px 0;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .section-left{
            .phone-hotline{
                text-align: center;
                font-size: 22px;
                line-height: 27px;
                strong{
                    color: #B31E1E;
                    font-size: 25px;
                    margin-top: 10px;
                    display: block;
                }
            }
        }
        .section-right{
            text-align: center;
            .about-thumb{
                margin-bottom: 30px;
            }
            .phone-hotline{
                text-align: center;
                font-size: 22px;
                line-height: 27px;
                strong{
                    color: #B31E1E;
                    font-size: 25px;
                }
            }
        }
        ul{
            clear: both;
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                margin-bottom: 20px;
                position: relative;
                display: flex;

                &::before {
                    content: '';
                    position: absolute;
                    border-left: 1px dotted #999;
                    width: 1px;
                    height: 120px;
                    left: 24px;
                    top: 0;
                }

                h2, h3{
                    margin: 0 0 10px 0;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                strong {
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    background: #999;
                    color: #fff;
                    line-height: 50px;
                    font-size: 30px;
                    float: left;
                    margin-right: 15px;
                    border-radius: 50%;
                    font-weight: 700;
                }
                p {
                    margin: 0 0 10px 65px;
                }
                a{}
            }
        }
    }
}

.about-section-2 {
    background-image: url(http://demo.mypagevn.com/giaydantuong/wp-content/uploads/2016/06/bg2.jpg?id=4080) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    
    h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
        color: #ffffff;
        margin-bottom: 20px;
    }
    p {
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
    }
}

.news-section-content{
    clear: both;
    .section-left{
        .thumb-info{
            img{
                width: 100%;
            }
        }
    }
    .section-right{
        ul.post-list{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                position: relative;
                display: flex;
                margin-bottom: 10px;
                .item-thumb{
                    max-width: 16%;
                    float: left;
                    margin-right: 15px;
                    img{
                        height: auto;
                    }
                }

                .item-content{

                }

                a{

                }
            }
        }
    }
}

.section-pricing-area{
    .pb-100 {
        padding-bottom: 100px;
    }
    .pt-100 {
        padding-top: 100px;
    }
    a{
        text-decoration:none;
    }
    .section-title h4 {
      font-size: 14px;
      font-weight: 500;
      color: #777;
    }
    .section-title h2 {
        font-size: 32px;
        text-transform: capitalize;
        margin: 15px 0;
        display: inline-block;
        position: relative;
        font-weight: 700;
        padding-bottom: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    .section-title p {
        font-weight: 300;
        font-size: 14px;
    }
    .black-bg .section-title h2, .black-bg .section-title h4, .black-bg .section-title p {
      color:#fff
    }
    .section-title h2:before {
      position: absolute;
      content: "";
      width: 150px;
      height: 1px;
      background-color: #777;
      bottom: 0;
      left: 50%;
      margin-left: -75px;
    }
    .section-title h2:after {
      position: absolute;
      content: "";
      width: 80px;
      height: 3px;
      background-color: #e16038;
      border: darkblue;
      bottom: -1px;
      left: 50%;
      margin-left: -40px;
    }
    .section-title {
      margin-bottom: 70px;
    }
    .single-price {
        text-align: center;
        padding: 30px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
    .price-title h4 {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .price-tag {
      margin: 30px 0;
    }
    .price-tag {
        margin: 30px 0;
        background-color: #fafafa;
        color: #000;
        padding: 10px 0;
    }
    .center.price-tag {
        background-color: tomato;
        color:#fff
    }
    .price-tag h2 {
        font-size: 45px;
        font-weight: 600;
        font-family: poppins;
    }
    .price-tag h2 span {
      font-weight: 300;
      font-size: 16px;
      font-style: italic;
    }
    .price-item ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .price-item ul li {
      font-size: 14px;
      padding: 5px 0;
      border-bottom: 1px dashed #eee;
      margin: 5px 0;
    }
    .price-item ul li:last-child {
      border-bottom: 0;
    }
    .single-price a {
      margin-top: 15px;
    }
    a.box-btn {
        background-color: tomato;
        padding: 5px 20px;
        display: inline-block;
        color: #fff;
        text-transform: capitalize;
        border-radius: 3px;
        font-size: 15px;
        transition: .3s;
    }
    a.box-btn:hover, a.border-btn:hover {
        background-color: #d35400;
    }
}

.thumbnail-slider-section-content{
    
    .carousel-inner { margin: auto; width: 95%; }
    .carousel-control{ width:  4%; 
        text-shadow:none;
    }
    .carousel-control.left,
    .carousel-control.right {
        background-image:none;
    }
    
    .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
        margin-top:-10px;
        margin-left: -25px;
        color: #dd994c;
    }

    .carousel-control .glyphicon-chevron-right {
        margin-right: -25px;
    }

    .carousel-inner {
        a {
            display:table-cell;
            height: 180px;
            width: 200px;
            vertical-align: middle;
        }
        img {
            max-height: 150px;
            margin: auto auto;
            max-width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .carousel-inner > .item.next,
    .carousel-inner > .item.active.right {
        left: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.left {
        left: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

}
@media (min-width: 767px) and (max-width: 992px ) {
    .carousel-inner > .item.next,
    .carousel-inner > .item.active.right {
        left: 0;
        -webkit-transform: translate3d(50%, 0, 0);
        transform: translate3d(50%, 0, 0);
    }
    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.left {
        left: 0;
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
    }
}
@media (min-width: 992px ) {

    .carousel-inner > .item.next,
    .carousel-inner > .item.active.right {
        left: 0;
        -webkit-transform: translate3d(16.7%, 0, 0);
        transform: translate3d(16.7%, 0, 0);
    }
    .carousel-inner > .item.prev,
    .carousel-inner > .item.active.left {
        left: 0;
        -webkit-transform: translate3d(-16.7%, 0, 0);
        transform: translate3d(-16.7%, 0, 0);
    }

}