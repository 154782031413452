ul.socials-list-fanpage{
    clear: both;
    margin: 0;
    padding: 0;
    li{
        list-style: none;
        display: inline-block;
        clear: both;
        margin: 0;
        padding: 0;
        a{
            color: $footer-color;
            text-decoration: none;
            font-size: 16px;
            display: block;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            .fa{ color: $footer-color; }
            &:hover{
                color: $footer-color;
            }
        }
    }
}

.box-socials-detail {
    clear: both;
    margin: 10px 0;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            margin-right: 10px;
            line-height: 5px;
        }
    }
}