.comment-layout{

    .form-group{
        // margin-bottom: 7px;
    }
    em.invalid{
        display: none;
        visibility: hidden;
    }

    .active em.invalid{
        visibility: visible;
        display: table-row;
    }

    .comment-header{
        h3{
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .comment-list{

        overflow:auto;
        min-height:50px;
        max-height:300px;
        border:solid 1px #efefde;
        margin-bottom: 20px;

        .comment-item{
            padding: 10px;

            p.body{
                margin-bottom: 5px;
            }

            .fa-user{
                font-size: 25px;
                margin-right: 10px;
            }

            label{
                margin: 0;
                line-height: 25px;
                font-weight: normal;
                font-style: italic;
                font-size: 12px;

                span{
                    font-weight: bold;
                    font-style: normal;
                    font-size: 13px;
                }
            }
        }

        .comment-item.item-class{
            background: #efefde;
        }
    }



    /*
     *  STYLE 2
     */

    #comment-list::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 3px #efefde;
        border-radius: 3px;
        background-color: #F5F5F5;
    }

    #comment-list::-webkit-scrollbar
    {
        width: 5px;
        background-color: #F5F5F5;
    }

    #comment-list::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 3px #efefde;
        background-color: #b58f47;
    }
}
