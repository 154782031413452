.post-tag-list {
    clear: both;
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            display: inline;
            .fa-tags{
                font-size: 20px;
                margin-right: 5px;
            }
            a {
                padding: 3px 5px;
            }

            .btn-u{
                padding: 2px 10px;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -o-border-radius: 5px;
                font-size: 12px;
            }
        }
    }
}

.tags-list-template{
    .page-title {
        span {
            text-transform: none;
            font-size: 20px;
        }
    }
}