.breadcrumbs {
  clear: both;
  padding: 10px 0;
  .page-title{
    margin: 0;
  }
}


/*Breadcrumbs v4
------------------------------------*/
.breadcrumbs {
  width: 100%;
  padding: 25px 0;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0,0,0,.3);
  }

  .container {
    position: relative;
  }

  span.page-name {
    color: $text-color;
    display: block;
    font-size: 18px;
    font-weight: 200;
    margin: 0 0 5px 3px;
  }

  .tag-heading {
    color: #ffffff;
    font-size: 25px;
    font-weight: 200;
    margin: 0 0 20px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .breadcrumb-v4-in {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .breadcrumb-v4-in > li {
    font-size: 13px;
    display: inline-block;
    color: #ffffff;
  }

  .breadcrumb-v4-in > li + li:before {
    content: "\f105";
    margin-left: 7px;
    padding-right: 8px;
    font-family: FontAwesome;
    color: #ffffff;
  }

  .breadcrumb-v4-in li a {
    color: #ffffff;
  }
}

@media (max-width: 768px) {
  .breadcrumbs {
    text-align: center;

    span.page-name {
      font-size: 18px;
    }

    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}