.articles-list{
  
  .article-item{
    .article-thumb{
      border: 1px solid transparent;
      &:hover{
        border-color: $gold-color2;
      }
    }

    .article-des{
      h2,h3,h4,h5{
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.article-item{
  margin-bottom: 20px;
}